declare var RELEASE_ID: string;

export const loadDynamicScript = (scriptID: string, src: string, callback: () => void) => {
  const existingScript = document.getElementById(scriptID);

  if (!existingScript) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `${src}?ver=${RELEASE_ID}`
    script.id = scriptID;
    script.onload = () => {
      if (callback) callback();
    }

    script.onerror = () => {
      throw new Error(`Error loading script scriptUrl: ${src}`)
    }

    document.head.appendChild(script);
  }
}
