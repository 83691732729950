import {Routes} from '../router/Routes';

export default function findPage(path: Routes | string): Routes | string {
  let currentPage = ''
  Object.entries(Routes).forEach(
    ([name, route]) => {
      if (path.includes(route)) {
        currentPage = route;
      }
    }
  );

  return currentPage;
}
