import {History} from 'history';
import {Dispatch} from 'redux';
import {CancelSubscriptionFailureReduxAction, CheckTrialRequestReduxAction, CheckTrialSuccessReduxAction} from './assetsActionsTypes';
import {assetsService} from '../../../services/assets/assets';
import {AssetsConstants} from '../../../constants';

export function checkTrial(history: History) {
  return (dispatch: Dispatch<CheckTrialRequestReduxAction | CheckTrialSuccessReduxAction | CancelSubscriptionFailureReduxAction>) => {
    dispatch(request());
    return assetsService.checkTrial(history)
      .then(
        () => {
          dispatch(success());
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request(): CheckTrialRequestReduxAction {
    return {type: AssetsConstants.CHECK_TRIAL_REQUEST, payload: {}}
  }

  function success(): CheckTrialSuccessReduxAction {
    return {type: AssetsConstants.CHECK_TRIAL_SUCCESS, payload: {}}
  }

  function failure(error: Error): CancelSubscriptionFailureReduxAction {
    return {type: AssetsConstants.CHECK_TRIAL_FAILURE, payload: error}
  }
}
