export default function isIpad(): boolean {
  const userAgent = window.navigator.userAgent;
  if (userAgent.indexOf('iPad') > -1) {
    return true;
  }

  if (userAgent.indexOf('Macintosh') > -1) {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {
      return false;
    }
  }

  return false;
}
