import {Asset} from '../../../services/assets/assetsTypes';
import {AssetDuration, PlanTypes} from '../../../constants';

export function determinePlanType(asset: Asset | null): string {
  if (!asset) {
    return '';
  }

  if (asset.is_trial) {
    return PlanTypes.trial;
  }

  if (asset.duration === AssetDuration.monthly) {
    return PlanTypes.month;
  }

  if (asset.duration === AssetDuration.yearly) {
    return PlanTypes.year;
  }

  return '';
}
