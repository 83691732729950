// Full fill with helpers
export {default as animate} from './animate';
export {default as authHeader} from './authHeader';
export {default as determineDesktopModel} from './determineDesktopModel';
export {default as determineErrorLevelForSentry} from './determineErrorLevelForSentry';
export {default as determineIOSModel} from './determineIOSModel';
export {default as formDeviceIdArray} from './formDeviceIdArray';
export {default as getAffid} from './getAffid';
export {default as getBrowserInfo} from './getBrowserInfo';
export {default as getDeviceId, generateDeviceId} from './getDeviceId';
export {default as getPlatform, PlatformNames} from './getPlatform';
export {default as guid} from './guid';
export {default as handleCommonErrorResponse} from './handleCommonErrorResponse';
export {default as handleResponse} from './handleResponse';
export {default as hotjarTriggerPoll} from './hotjarTriggerPoll';
export {default as isIpad} from './isIpad';
export {default as objectOmitNull} from './objectOmitNull';
export {default as sendGtmCustomEvent} from './sendGtmCustomEvent';
export {timeConverter, formatAMPM, localTimeConverter, dateFormat} from './timeConverter';
export {default as isTouchDevice} from './isTouchDevice';
export {isAdaptiveMarkup} from './isAdaptiveMarkup';
export {default as isEmpty} from './isEmpty';
export {default as determineDeviceQuantityText} from './determineDeviceQuantityText';
export {fullFillStringWithPlaceholder} from './fullFillStringWithPlaceholder';
export {default as vh} from './calculateVHIntoPixel';
export {default as findPage} from './findPage';
export {default as removeKeyFromObject} from './removeKeyFromObject';
export {capitalizeFirstLetter} from './capitalizeFirstLetter';
export {default as delay} from './calcExpDelay';
export {default as isMobile} from './isMobile';
export {loadDynamicScript} from './loadDynamicScript';
export {default as isObject} from './isObject';


